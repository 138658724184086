window.view_campaign = function ()
{

    return {

        edit_name: false,
        edit_name_value: '',

        show_email_cancelled: false,
        show_email_deleted: false,
        show_confirm_cancel_campaign: false,
        show_confirm_delete_campaign: false,
        show_send_test_email: false,
        show_confirm_archive_campaign: false,
        show_email_archived: false,

        init()
        {
        },

        save_name(batch_id, name)
        {
            let scope = this;
            let formData = new FormData();
            formData.append('batch_id', batch_id);
            formData.append('name', name);

            axios.post('/email_marketing/save_name', formData)
                .then(function (response)
                {
                    scope.$refs.batch_name.innerHTML = name;
                    scope.edit_name = false;
                    notify.success('Name updated');
                })
                .catch(function (error)
                {
                });
        },

        confirm_cancel_campaign(batch_id, action)
        {
            let scope = this;
            if(action == 'cancel') {
                scope.show_confirm_cancel_campaign = true;
                scope.$refs.cancel_campaign_batch_id.value = batch_id;
            } else if(action == 'archive') {
                scope.show_confirm_archive_campaign = true;
                scope.$refs.archive_campaign_batch_id.value = batch_id;
            } else if(action == 'delete') {
                scope.show_confirm_delete_campaign = true;
                scope.$refs.delete_campaign_batch_id.value = batch_id;
            }
        },

        cancel_campaign(ele, action)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            let batch_id;
            let success_message;

            if(action == 'cancel') {
                batch_id = scope.$refs.cancel_campaign_batch_id.value;
                show_loading_button(ele, 'Cancelling ... ');
                success_message = 'Campaign cancelled successfully';
            } else if(action == 'archive') {
                batch_id = scope.$refs.archive_campaign_batch_id.value;
                show_loading_button(ele, 'Archiving ... ');
                success_message = 'Campaign archived successfully';
            } else if(action == 'delete') {
                batch_id = scope.$refs.delete_campaign_batch_id.value;
                show_loading_button(ele, 'Deleting ... ');
                success_message = 'Campaign deleted successfully';
            }

            axios.post('/email_marketing/'+action+'_campaign', { batch_id: batch_id })
                .then(function (response)
                {
                    scope.show_confirm_delete_campaign = false;
                    scope.show_confirm_cancel_campaign = false;
                    scope.show_confirm_archive_campaign = false;
                    if(action == 'delete') {
                        scope.show_email_deleted = true;
                    } else if(action == 'archive') {
                        scope.show_email_archived = true;
                    } else if(action == 'cancel') {
                        scope.show_email_cancelled = true;
                    }
                    notify('success', success_message);
                    ele.innerHTML = button_html;
                    
                })
                .catch(function (error)
                {
                });
        },

        clone_campaign(ele, batch_id)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Cloning ... ');
            axios.post('/email_marketing/clone_campaign', { batch_id: batch_id })
                .then(function (response)
                {
                    window.location.href = '/email_marketing/create_email_campaign/' + response.data.batch_id;
                });
        },

        open_send_test_email(campaign_id)
        {
            let scope = this;
            scope.show_send_test_email = true;
            scope.$refs.campaign_id.value = campaign_id;
        },

        send_test_email(ele, email_addresses)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Sending ... ');
            axios.post('/email_marketing/send_test_email', {
                campaign_id: scope.$refs.campaign_id.value,
                email_addresses: email_addresses
            })
                .then(function (response)
                {
                    notify('success', 'Test email sent successfully');
                    scope.show_send_test_email = false;
                });
        }


    }

}