if (document.URL.match(/queue_monitoring/))
{

    window.queue_monitor = function ()
    {
        
        return {

            init()
            {
                this.get_stats();
                setInterval(this.get_stats, 2000);
                setInterval(this.get_jobs_by_status, 1000);
                // setTimeout(this.get_jobs_by_status, 1000);
            },

            get_jobs()
            {
                axios.get('/jobs')
                .then(response => {
                    document.getElementById('jobs_div').innerHTML = response.data;
                });
            },

            get_stats()
            {
                let scope = this;
                axios.get('/admin/queue_monitoring/get_stats')
                .then(response => {
                    let stats = response.data;
                    let html = '';
                    
                    stats.forEach(stat => {
                        html += `
                        <div class="border p-1">
                            <div class="whitespace-nowrap text-sm flex space-x-2">
                                <div>${stat['name']}: </div>
                                <div class="bg-emerald-600 text-white px-2 rounded-md">${stat['count']}</div>
                                <div class="bg-violet-600 text-white px-2 rounded-md">q${stat['queue']}</div>
                                <div>
                                    <button class="text-red-500" @click="delete_job_by_name('${stat['name']}')"><i class="fa-solid fa-trash"></i></button>
                                </div>
                            </div>
                            <div class="text-xs">${stat['next_run_time_display']} (${stat['time_from_now']})</div>
                        </div>`;
                    });
                    document.getElementById('stats_div').innerHTML = html;
                });
            },

            delete_jobs(type)
            {
                if(confirm('Are you sure you want to delete jobs?'))
                {
                    let scope = this;
                    axios.post('/admin/queue_monitoring/delete_jobs', {type: type})
                    .then(response => {
                        notify('success', 'All jobs deleted');
                        scope.$refs.iframe.contentWindow.location.reload();
                    });
                }
            },

            delete_job_by_name(name)
            {
                if(confirm('Are you sure you want to delete jobs?'))
                {
                    let scope = this;
                    axios.post('/admin/queue_monitoring/delete_job_by_name', {name: name})
                    .then(response => {
                        notify('success', 'Job deleted');
                        scope.$refs.iframe.contentWindow.location.reload();
                    });
                }
            },

            get_jobs_by_status()
            {
                if(document.getElementById('jobs_iframe').contentWindow.document.getElementById('jobs_by_status'))
                {
                    let scope = this;
                    axios.get('/admin/queue_monitoring/get_jobs_by_status')
                    .then(response => {
                    let jobs = response.data;
                    
                    let html = '';
                        let container = document.getElementById('jobs_iframe').contentWindow.document.getElementById('jobs_by_status');
                    jobs.forEach(job => {
                        html += `<div class="flex justify-between"><div>${job['status']}:</div> <div class="text-right">${job['count']}</div></div>`;
                    });
                        if(container)
                        {
                            container.innerHTML = html;
                        }
                    });
                }
            }
        }

    }

}
