window.listing_websites = function ()
{

    return {
        show_notification_listing_websites: false,
        show_listings_div: false,
        mls: '',
        show_edit_link_modal: false,
        show_error_message: false,

        init()
        {

        },

        show_edit_link(listing_id, listing_link)
        {
            let scope = this;
            scope.show_edit_link_modal = true;
            scope.$refs.new_listing_link.value = listing_link;
            scope.$refs.listing_id.value = listing_id;

        },

        save_link(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let listing_id = scope.$refs.listing_id.value;
            let new_value = scope.$refs.new_listing_link.value;

            let formData = new FormData();
            formData.append('listing_id', listing_id);
            formData.append('new_value', new_value);

            axios.post('/agents/listings/listing_websites/save_link', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    if (response.data.error)
                    {
                        scope.show_error_message = true;
                        return;
                    }
                    scope.update_listing(listing_id);
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        update_listing(listing_id, replace = true)
        {
            let scope = this;
            axios.get('/agents/listings/listing_websites/update_listing/' + listing_id)
                .then(function (response)
                {
                    // replace the listing with the new listing
                    let listing_div = document.getElementById('listing_' + listing_id);
                    let new_listing_div_html = response.data;
                    let new_listing_div = document.createElement('div');
                    new_listing_div.innerHTML = new_listing_div_html;
                    scope.unwrap_element(new_listing_div);
                    if (replace == true)
                    {
                        listing_div.parentNode.replaceChild(new_listing_div, listing_div);
                    } else
                    {
                        let active_listings_div = document.getElementById('active_listings_div');
                        active_listings_div.prepend(new_listing_div);
                    }
                    scope.show_edit_link_modal = false;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        search_listings(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Searching ... ');
            remove_form_errors();
            scope.show_listings_div = false;

            let formData = new FormData();
            formData.append('mls', this.mls);

            axios.post('/agents/seller_concessions/search_mls', formData)
                .then(function (response)
                {
                    scope.show_listings_div = true;
                    let listings_div = document.getElementById('listings_div');
                    if (response.data.error)
                    {
                        listings_div.innerHTML = response.data.error;
                        listings_div.classList.add('bg-red-50', 'border-red-200');
                        listings_div.classList.remove('bg-emerald-50', 'border-emerald-200');
                    } else
                    {
                        listings_div.innerHTML = response.data;
                        listings_div.classList.add('bg-emerald-50', 'border-emerald-200');
                        listings_div.classList.remove('bg-red-50', 'border-red-200');
                    }
                    ele.innerHTML = button_html;

                })
                .catch(function (error)
                {
                    console.log(error);
                    ele.innerHTML = button_html;
                    display_errors(error, ele, button_html);
                });

        },

        add_listing(ele, listing_id)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Saving ... ');
            remove_form_errors();

            let formData = new FormData();
            formData.append('listing_id', listing_id);

            axios.post('/agents/listings/listing_websites/add_listing', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    if (response.data.error)
                    {
                        let add_listing_response = document.getElementById('add_listing_response');
                        let html = '<div class="bg-red-50 border-red-200 rounded-md p-4"><i class="fa-light fa-circle-exclamation mr-2"></i>' + response.data.error + '</div>';
                        add_listing_response.innerHTML = html;
                    } else
                    {
                        let listing_div = document.getElementById('listing_' + listing_id);
                        if(!listing_div) {
                            scope.update_listing(listing_id, false);
                        }
                        scope.show_listings_div = false;
                        notify('success', 'Listing Successfully Added');
                        window.scrollTo(0, 0);
                    }
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        validate_input(event)
        {
            const allowedChars = /^[a-zA-Z0-9_]*$/;
            const char = event.key;

            if (!allowedChars.test(char))
            {
                event.preventDefault();
            }
        },

        unwrap_element(element)
        {
            if (element.parentNode)
            {
                const parent = element.parentNode;

                // Move all children of the parent to its grandparent
                while (parent.firstChild)
                {
                    parent.parentNode.insertBefore(parent.firstChild, parent);
                }

                // Remove the now-empty parent
                parent.parentNode.removeChild(parent);
            }
        },
    }
}