window.inbox = function ()
{
    
    return {

        search_value: '',
        show_search_results: false,
        show_selected_agent: false,

        init()
        {
        },

        search_agents()
        {
            let scope = this;
            let search_input = scope.$refs.search_agents_input;
            remove_form_errors();

            axios.get('/email_marketing/inbox/search_agents', {
                params: {
                    search: search_input.value
                },
            })
                .then(function (response)
                {
                    scope.show_search_results = true;
                    scope.$refs.search_results.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        clear_search()
        {
            this.search_value = '';
            this.$refs.search_agents_input.value = '';
            this.show_search_results = false;
        },

        set_agent(member_key, name, email, office)
        {
            this.$refs.member_key.value = member_key
            this.show_selected_agent = true;
            this.clear_search();
            this.$refs.selected_agent_name.innerHTML = name;
            this.$refs.selected_agent_email.innerHTML = email;
            this.$refs.selected_agent_office.innerHTML = office;
        },

        add_lead_to_nutshell(ele)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Adding Lead ... ');
            remove_form_errors();
            
            let form = scope.$refs.add_lead_to_nutshell_form;
            let formData = new FormData(form);
            
            axios.post('/email_marketing/inbox/add_lead_to_nutshell', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.clear_search();
                    notify('success', 'Lead added to NutShell');
                    scope.reset_form();
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                });
        },

        reset_form()
        {
            this.$refs.add_lead_to_nutshell_form.reset();
            this.show_selected_agent = false;
            this.$refs.member_key.value = '';
            this.$refs.selected_agent_name.innerHTML = '';
            this.$refs.selected_agent_email.innerHTML = '';
            this.$refs.selected_agent_office.innerHTML = '';
        }

    }

}