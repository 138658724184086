window.enable_text_editor_in_development = false;

if (app_env == 'production')
{
    window.enable_text_editor_in_development = true;
}

window.addEventListener('load', (event) =>
{

    global_format_money();

    // setInterval(global_format_phones, 1000);

    setInterval(form_elements, 500);
    // setInterval(numbers_only, 500);

    document.querySelectorAll('.filepond--credits').forEach(function (div)
    {
        div.style.display = 'none';
    });

    setInterval(() =>
    {
        tippy('[data-tippy-content]', {
            allowHTML: true,
            theme: 'custom',
            animation: 'scale',
            trigger: 'click',
        });
    }, 1000);




});


window._token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
if (!window._token)
{
    window.location.href = '/login';
}
// axios headersObj
window.axios_options = {
    headers: {
        'X-CSRF-TOKEN': _token
    }
};

axios.interceptors.response.use(function (response)
{
    // tippy('[data-tippy-content]', {
    //     allowHTML: true,
    //     theme: 'light',
    //     trigger: 'click',
    // });
    numbers_only();
    form_elements();
    global_format_phones();
    return response;
}, function (error)
{
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error);
});

// if (document.URL.match(/tpuserportal.com\/[a-z]+/)) {

//     if (!document.URL.match(/(login|forgot-password|register|reset-password|verify-email|verification-notification|confirm-password)/)) {
//         setInterval(function checkSession() {
//             axios.get('/is-logged-in')
//                 .then(function (response) { })
//                 .catch(function (error) {
//                     if (error) {
//                         location.reload();
//                     }
//                 });
//         }, 10000);
//     }
// }


window.broadcast_add = function (table, column, values, ele)
{
    axios.get('/broadcast_add', {
        params: {
            table: table,
            column: column,
            values: values
        },
    })
        .then(function (response)
        {
            let id = response.data.id;
            broadcast_check(id, ele);
        })
        .catch(function (error)
        {

        });
}

window.broadcast_check = function (id, ele)
{
    let check_interval = setInterval(() =>
    {
        axios.get('/broadcast_check', {
            params: {
                id: id
            },
        })
            .then(function (response)
            {
                if (response.data.completed)
                {
                    clearInterval(check_interval);
                    document.querySelector(ele).classList.toggle('hidden');
                    document.querySelector(ele).classList.toggle('flex');
                }
            })
            .catch(function (error)
            {

            });
    }, 1000);
}

window.focus_cursor_end = function (ele)
{
    setTimeout(() =>
    {
        ele.focus();
        const len = ele.value.length;
        ele.setSelectionRange(len, len);
    }, 100);
}

window.notify = function (type = 'success', message)
{
    if (!document.querySelector('.custom-notification-div'))
    {
        let notification_div = document.createElement('div');

        notification_div.innerHTML = `<div class="custom-notification-div h-auto z-0 opacity-90 w-full fixed md:w-300-px bottom-0  md:right-0"></div>`;
        document.body.append(notification_div);
    }

    let custom_notification_div = document.querySelector('.custom-notification-div');

    let notification = document.createElement('div');

    let icon = 'fa-check-circle';
    let color = 'green';
    if (type == 'error')
    {
        icon = 'fa-exclamation-circle';
        color = 'red';
    } else if (type == 'warning')
    {
        icon = 'fa-exclamation-triangle';
        color = 'yellow';
    }

    notification.innerHTML = `
    <div class="p-2 md:m-1 bg-` + color + `-600 text-white md:rounded md:shadow-lg flex notify-alert">
        <div class="w-[10%] flex justify-center items-center">
            <i class="fa-solid ` + icon + ` fa-2x"></i>
        </div>
        <div class="w-[90%] flex items-center p-2">
        <span>` + message + `</span>
        </div>
    </div>
    `;
    custom_notification_div.append(notification);
    custom_notification_div.classList.add('z-50');

    notification.addEventListener('click', function ()
    {
        notification.remove();
    });

    setTimeout(function ()
    {
        notification.remove();
        if (!custom_notification_div.querySelector('.notify-alert'))
        {
            setTimeout(function ()
            {
                custom_notification_div.classList.remove('z-50');
                custom_notification_div.classList.add('z-0');
                custom_notification_div.classList.add('h-0');
            }, 200);
        }
    }, 5000);
}

window.trigger_change = function (target, action)
{
    target.dispatchEvent(new Event(action));
}

window.clear_select = function (ele)
{
    ele.closest('.relative').querySelector('select').value = '';
    ele.closest('.cancel-div').classList.add('hidden');
}

window.show_loading = function ()
{
    document.querySelector('.page-loading').classList.remove('hidden');
    document.querySelector('.page-loading').classList.add('block');
}
window.hide_loading = function ()
{
    document.querySelector('.page-loading').classList.add('hidden');
    document.querySelector('.page-loading').classList.remove('block');
}

window.ele_loading = function (ele)
{
    ele.classList.add('relative', 'min-h-[100px]');
    ele.innerHTML = ' \
    <div class="w-full h-full absolute top-0 left-0 flex justify-around items-center bg-white opacity-75 z-50"> \
        <span class="text-gray-700 opacity-75"> \
            <i class="fas fa-circle-notch fa-spin fa-4x"></i> \
        </span> \
    </div>';
}

window.main_search = function ()
{

    return {

        show_search_results_div: false,

        search(search_input)
        {

            let scope = this;
            let value = search_input.value;
            let search_results_div = scope.$refs.search_results_div;

            if (value.length > 0)
            {

                axios.get('/search', {
                    params: {
                        value: value
                    },
                })
                    .then(function (response)
                    {
                        if (response)
                        {
                            search_results_div.innerHTML = response.data;
                            scope.show_search_results_div = true;

                        } else
                        {
                            scope.show_search_results_div = false;
                        }
                    })
                    .catch(function (error)
                    {

                    });
            } else
            {
                scope.show_search_results_div = false;
            }

        }

    }

}

window.display_errors = function (error, ele, button_html, size = null)
{
    if (error)
    {
        if (error.response)
        {
            if (error.response.status == 422)
            {
                let errors = error.response.data.errors;
                show_form_errors(errors, size);
                ele.innerHTML = button_html;
            }
        }
    }
}

window.show_form_errors = function (errors, size)
{
    remove_form_errors();
    let form_errors = [Object.entries(errors)[0]];
    form_errors.forEach(([key, value]) =>
    {

        let field = `${key}`;
        value = `${value}`;
        let message = value;
        if (Array.isArray(value))
        {
            message = value[0];
        }

        let element = '';
        if (field.match(/\.[0-9]+$/))
        {
            let fields = field.split('.');
            let elements = document.querySelectorAll('[name="' + fields[0] + '[]"]');
            element = elements[fields[1]];
        } else
        {
            element = document.querySelector('[name="' + field + '"]');
            if (!element)
            {
                element = document.querySelector('[name="' + field + '[]"]');
            }
        }
        let label = '';
        let text_size = 'text-xs';
        if (size == 'large')
        {
            text_size = 'text-lg';
        }
        let error_message = '<div class="relative error-message"><div class=" whitespace-nowrap px-2 pb-3 pt-1 mt-1 bg-white/80 absolute text-red-500 ' + text_size + '"><i class="fa-duotone fa-circle-arrow-up mr-2"></i> ' + message + '</div></div>';
        if (element && (element.getAttribute('type') == 'checkbox' || element.getAttribute('type') == 'radio'))
        {
            error_message = '<div class="error-message absolute top-8 w-72 text-red-500 ' + text_size + ' mt-1"><i class="fa-duotone fa-circle-arrow-up mr-2"></i> ' + message + '</div>';
            element.parentNode.classList.add('relative');
        }

        if (element)
        {
            if (element.getAttribute('type') == 'hidden')
            {
                label = document.querySelector('.' + element.getAttribute('data-error-div'));
                console.log(label);
                label.insertAdjacentHTML('beforeend', error_message);
                label.classList.add('relative');
            } else
            {
                if (element.parentNode.tagName == 'LABEL')
                {
                    label = element.parentNode;
                    label.insertAdjacentHTML('beforeend', error_message);
                } else
                {
                    label = element.parentNode.querySelector('label');
                    element.parentNode.insertAdjacentHTML('beforeend', error_message);
                }

            }


            //let error_message = element.closest('label').querySelector('.error-message');
            //error_message.innerHTML = message;
            scroll_above(element);
            notify('error', 'Field not completed');
        }

    });

}

window.remove_form_errors = function (event = null)
{

    if (event)
    {
        let label = event.target.closest('label');
        label.querySelector('.error-message').innerHTML = '';
        label.querySelector('.error-message').classList.toggle('hidden');
    } else
    {
        document.querySelectorAll('.error-message').forEach(function (error_div)
        {
            // error_div.innerHTML = '';
            error_div.remove();
        });
    }
}

window.check_all = function (table)
{
    return {
        checked: false,
        init()
        {
            let check_all_input = table.querySelector('.check-all');
            let checkboxes = table.querySelectorAll('.checkbox');

            check_all_input.addEventListener('click', function ()
            {
                this.checked = check_all_input.checked;
                [...checkboxes].map((el) =>
                {
                    el.checked = this.checked;
                });
            });

            [...checkboxes].map((el) =>
            {
                el.addEventListener('click', function ()
                {
                    if (table.querySelectorAll('tbody .checkbox:checked').length == 0)
                    {
                        check_all_input.checked = false;
                    }
                });
            });

        }

    }
}

window.enable_text_editor = function ()
{
    if (app_env != 'production')
    {
        if (enable_text_editor_in_development == true)
        {
            let editors = sessionStorage.getItem('text_editors');

            if (editors)
            {
                editors = JSON.parse(editors);
                editors.forEach(function (options)
                {
                    text_editor(options);
                });
            }
        }
    }
}

window.text_editor = function (options)
{
    if (app_env != 'production')
    {
        let editors = sessionStorage.getItem('text_editors');
        if (editors)
        {
            editors = JSON.parse(editors);
            editors.push(options);
            sessionStorage.setItem('text_editors', JSON.stringify(editors));
        } else
        {
            sessionStorage.setItem('text_editors', JSON.stringify([options]));
        }

        if (enable_text_editor_in_development == false)
        {
            return;
        }
    }

    const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;

    if (options.selector == '')
    {
        options.selector = '.text-editor';
    }
    options.lists_indent_on_tab = true;
    // options.content_css = '/css/tinymce.css';
    options.content_style = "body { font-family: Arial; font-size: 10pt }";
    options.branding = false;
    // options.newline_behavior = 'linebreak';
    // options.fix_list_elements = true;
    options.toolbar_sticky = false;
    // options.toolbar_sticky_offset = isSmallScreen ? 102 : 108;
    // options.images_upload_handler = image_upload_handler;

    /* enable title field in the Image dialog*/
    options.image_title = true;
    /* enable automatic uploads of images represented by blob or data URIs*/
    options.automatic_uploads = true;
    options.images_upload_url = '/text_editor/file_upload';


    tinymce.remove(options.selector);
    tinymce.init(options);


    // document.querySelector('[title="Insert/edit image"]').addEventListener('click', function () {
    //     document.querySelector('.tox-dialog__body-nav').lastChild.click();
    // });

    let upload_interval = setInterval(() =>
    {
        if (document.querySelector('.tox-dialog__body-nav'))
        {
            document.querySelector('.tox-dialog__body-nav').lastChild.click();
            clearInterval(upload_interval);
        }
    }, 1000);


}



window.scroll_above = function (element)
{

    let yOffset = -150;
    let y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({
        top: y,
        behavior: 'smooth'
    });
    element.focus({
        preventScroll: true
    });

}


window.show_loading_button = function (button, text)
{
    button.innerHTML = text + ' <i class="fa-solid fa-circle-notch fa-spin ml-2 mb-1"></i>';
}

window.decode_HTML = function (html)
{
    let txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

window.randomHSL = function ()
{
    return `hsla(${~~(360 * Math.random())},70%,70%,0.8)`
}

window.truncate_string = function (str, num)
{
    if (str.length > num)
    {
        return str.slice(0, num) + "...";
    } else
    {
        return str;
    }
}


window.get_location_details = function (container, member_id, zip, city, state, county = null)
{


    if (member_id)
    {
        container = document.querySelector(container + '[data-id="' + member_id + '"]');
    } else
    {
        container = document.querySelector(container);
    }
    zip = container.querySelector(zip);
    city = container.querySelector(city);
    state = container.querySelector(state);
    county = container.querySelector(county) || null;

    let zip_code = zip.value;

    if (zip_code.length == 5)
    {
        axios.get('/transactions/get_location_details', {
            params: {
                zip: zip_code
            },
        })
            .then(function (response)
            {
                city.value = response.data.city;
                state.value = response.data.state;
                if (county)
                {
                    let event = new Event('change');
                    state.dispatchEvent(event);
                    setTimeout(function ()
                    {
                        county.value = response.data.county;
                    }, 200);
                }
            })
            .catch(function (error)
            {

            });
    }
}

window.datatable_settings = {
    "autoWidth": false,
    "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
    ],
    "responsive": true,
    "destroy": true,
    "fixedHeader": true,
    "processing": true,
    "serverSide": true,
    "language": {
        search: '',
        searchPlaceholder: 'Search',
        "info": "_START_ to _END_ of _TOTAL_",
        "lengthMenu": "Show _MENU_",
    },

}

window.data_table = function (src, cols, page_length, table, sort_by, no_sort_cols, hidden_cols, show_buttons, show_search, show_info, show_paging, show_hide_cols = true, hide_header_and_footer = false)
{

    /*
    src = 'url'
    table = $('#table_id')
    sort_by = [1, 'desc'] - col #, dir
    no_sort_cols = [0, 8] - array of cols
    hidden_cols = [0, 8] - array of cols
    show_buttons = true/false
    show_search = true/false
    show_info = true/false
    show_paging = true/false
    show_hide_cols = true/false
    hide_header_and_footer = true/false
    */

    datatable_settings.ajax = src;
    datatable_settings.columns = cols;
    datatable_settings.serverSide = true;
    datatable_settings.processing = true;


    datatable_settings.pageLength = parseInt(10);
    if (page_length != '')
    {
        datatable_settings.pageLength = parseInt(page_length);
    }

    if (sort_by.length > 0)
    {
        datatable_settings.order = [
            [sort_by[0], sort_by[1]]
        ];
    }

    if (no_sort_cols.length > 0)
    {
        datatable_settings.columnDefs = [{
            orderable: false,
            targets: no_sort_cols
        }];
    }

    if (hidden_cols.length > 0)
    {
        hidden_cols.forEach(function (col)
        {
            datatable_settings.columnDefs.push({
                targets: [col],
                visible: false
            });
        });
    }

    let buttons = '';

    if (show_buttons == true)
    {

        if (show_hide_cols == true)
        {
            datatable_settings.buttons = [{
                extend: 'colvis',
                text: 'Hide Columns'
            }];
        }

        datatable_settings.buttons.push({
            extend: 'excelHtml5',
            exportOptions: {
                columns: ':visible'
            }
        }, {
            extend: 'pdfHtml5',
            exportOptions: {
                columns: ':visible'
            }
        });
        buttons = '<B>';


    }


    let search = '';
    if (show_search == true)
    {
        search = '<f>';
    }

    let info = '';
    if (show_info == true)
    {
        info = '<i>';
    }

    let paging = '';
    let length = '';
    datatable_settings.paging = false;
    if (show_paging == true)
    {
        paging = '<p>';
        datatable_settings.paging = true;
        length = '<l>';
    }

    if (hide_header_and_footer == true)
    {
        datatable_settings.drawCallback = function ()
        {
            $(this.api().table().header()).hide();
            $(this.api().table().footer()).hide();
        }
        info = '';
        paging = '';
        datatable_settings.paging = false;
        length = '';
        search = '';
        buttons = '';
        datatable_settings.buttons = [];
    }


    datatable_settings.dom = '<"flex justify-between flex-wrap items-center text-gray-600"' + search + info + length + buttons + '>rt<"flex justify-between items-center text-gray-600"' + info + paging + '>'

    let dt = table.DataTable(datatable_settings);


    style_dt_buttons();
    dt.on('draw', style_dt_buttons);


    return dt;

}

function style_dt_buttons()
{
    $('.dataTables_filter [type="search"]').attr('placeholder', 'Search');
    $('.dt-button').attr('class', ' buttons-colvis px-2 py-1 bg-sky-600 hover:bg-sky-700 active:bg-sky-700 focus:border-sky-700 ring-sky-700 inline-flex items-center border border-sky-700 rounded text-sm text-white tracking-tight focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 shadow hover:shadow-md');


    $('.paginate_button').removeClass('paginate_button').addClass('paginate_button_custom');
}


// Format Money
window.format_number = function (num)
{
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
window.global_format_number = function (num)
{
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 0
    });

    num = num.toString().replace(/[,\$]/g, '');
    return formatter.format(num);
}

window.global_format_number_with_decimals = function (num)
{
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    num = num.replace(/[,\$]/g, '').toString();
    return formatter.format(num);
}

window.format_money = function (ele)
{
    let val = ele.value.replace(/\$/, '');
    ele.value = '$' + global_format_number(val);
}

window.format_money_with_decimals = function (ele)
{
    let val = ele.value.replace(/\$/, '');
    ele.value = global_format_number_with_decimals(val);
}

window.global_format_money = function ()
{
    // $('.money, .money-decimal').each(function() {
    //     let val = $(this).val();
    //     if(val.match(/[a-zA-Z]+/)) {
    //         $(this).val(val.replace(/[a-zA-Z]+/,''));
    //     }
    // });

    let money = document.querySelectorAll('.money');

    if (money.length > 0)
    {

        money.forEach(function (input)
        {

            if (input.value != '')
            {
                format_money(input);
            }
            input.onkeyup = function ()
            {
                if (input.value != '')
                {
                    format_money(input);
                }
            }

        });

    }

    let money_decimal = document.querySelectorAll('.money-decimal');

    if (money_decimal.length > 0)
    {

        money_decimal.forEach(function (input)
        {

            if (input.value != '')
            {
                format_money_with_decimals(input);
            }
            input.onchange = function ()
            {
                if (input.value != '')
                {
                    format_money_with_decimals(input);
                }
            }

        });

    }

}


document.querySelectorAll('.ssn').forEach(function (input)
{

    input.addEventListener('keydown', (event) =>
    {

        let re = /\D/g; // remove any characters that are not numbers
        let soc_sec = input.value.replace(re, "");

        let ssa = soc_sec.slice(0, 3);
        let ssb = soc_sec.slice(3, 5);
        let ssc = soc_sec.slice(5, 9);
        input.value = ssa + "-" + ssb + "-" + ssc;

    });

});


// Numbers Only
window.numbers_only = function ()
{

    document.querySelectorAll('.numbers-only').forEach(function (input)
    {

        input.addEventListener('keydown', (event) =>
        {

            let allowed_keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'Control', 'v', 'c'];

            if (!input.classList.contains('no-decimals'))
            {
                allowed_keys.push('.');
            }
            if (!allowed_keys.includes(event.key))
            {
                event.preventDefault();
            } else
            {

                let max = input.getAttribute('max') || null;

                if (max)
                {
                    if (parseInt(input.value + event.key) > max)
                    {
                        event.preventDefault();
                        input.value = event.key;
                    }
                }
            }

        });

    });

}

// Format Phone
window.global_format_phone = function (obj)
{
    if (obj)
    {
        let numbers = obj.value.replace(/\D/g, ''),
            char = {
                0: '(',
                3: ') ',
                6: '-'
            };
        obj.value = '';
        for (let i = 0; i < numbers.length; i++)
        {
            if (i > 13)
            {
                return false;
            }
            obj.value += (char[i] || '') + numbers[i];
        }
    }
}
window.global_format_phones = function ()
{
    document.querySelectorAll('.phone').forEach(function (input)
    {
        input.classList.add('numbers-only');
        global_format_phone(input);
        input.setAttribute('maxlength', 14);
        input.addEventListener('keyup', (event) =>
        {
            global_format_phone(input);
        })
    });
}

function wrap(el, wrapper_class = null)
{
    let wrapper = document.createElement('div');
    if (wrapper_class)
    {
        wrapper.classList.add(wrapper_class);
    }

    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

// unwrap element
window.unwrap = function (wrapper)
{
    // place childNodes in document fragment
    var docFrag = document.createDocumentFragment();
    while (wrapper.firstChild)
    {
        var child = wrapper.removeChild(wrapper.firstChild);
        docFrag.appendChild(child);
    }

    // replace wrapper with document fragment
    wrapper.parentNode.replaceChild(docFrag, wrapper);
}

window.ucwords = function (str)
{
    // return (str + '')
    //     .replace(/^(.)|\s+(.)/g, function ($1)
    //     {
    //         return $1.toUpperCase()
    //     })
    return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

window.random_dark_color = function ()
{
    var lum = -0.25;
    var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6)
    {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = "#",
        c, i;
    for (i = 0; i < 3; i++)
    {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }
    return rgb;
}


window.global_get_url_parameters = function (key)
{
    // usage
    // let tab = global_get_url_parameters('tab');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has(key))
    {
        return urlParams.get(key);
    }
    return false;
}

window.add_copy_to_pre = function ()
{

    document.querySelectorAll('pre.copy').forEach(function (ele)
    {
        wrap(ele, 'wrapper_ele');
        let wrapper = document.querySelector('.wrapper_ele');
        wrapper.classList.add('relative');
        let copy_div = document.createElement('div');
        wrapper.append(copy_div);
        copy_div.classList.add('absolute', 'top-1', 'right-1');
        copy_div.innerHTML = '<a href="javascript:void(0)" @click="copy_text_from_ele($el.parentNode.previousSibling)"><i class="fa-duotone fa-copy text-sky-500"></i></a>';
        ele.addEventListener('click', function ()
        {
            copy_text_from_ele(this);
        });
        wrapper.classList.remove('wrapper_ele');
    });
}

window.copy_text_from_ele = function (ele)
{
    document.querySelectorAll('.copied').forEach(function (ele)
    {
        ele.classList.remove('copied');
    });
    ele.classList.add('copied');
    let text = ele.innerText;
    let input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.value = text;
    copy_to_clipboard(input);
    input.remove();
}

window.copy_to_clipboard = function (input)
{
    let text = input.value;
    notify('success', 'Copied to Clipboard');
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext)
    {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(text);
    } else
    {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = text;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) =>
        {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }

}

window.resizable_textarea = function (ele)
{
    ele.style.height = "1px";
    ele.style.height = (5 + ele.scrollHeight) + "px";
    ele.addEventListener('input', function ()
    {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 'px';
    });
}





class Badger
{
    constructor(options)
    {
        Object.assign(
            this, {
            backgroundColor: "#f00",
            color: "#fff",
            size: 0.8, // 0..1 (Scale in respect to the favicon image size)
            position: "se", // Position inside favicon "n", "e", "s", "w", "ne", "nw", "se", "sw"
            radius: 10, // Border radius
            src: "", // Favicon source (dafaults to the <link> icon href)
            onChange()
            {
            },
        },
            options
        );
        this.canvas = document.createElement("canvas");
        this.src = this.src || this.faviconEL.getAttribute("href");
        this.ctx = this.canvas.getContext("2d");
    }

    faviconEL = document.querySelector("link[rel$=icon]");

    _drawIcon()
    {
        this.ctx.clearRect(0, 0, this.faviconSize, this.faviconSize);
        this.ctx.drawImage(this.img, 0, 0, this.faviconSize, this.faviconSize);
    }

    _drawShape()
    {
        const r = this.radius;
        const xa = this.offset.x;
        const ya = this.offset.y;
        const xb = this.offset.x + this.badgeSize;
        const yb = this.offset.y + this.badgeSize;
        this.ctx.beginPath();
        this.ctx.moveTo(xb - r, ya);
        this.ctx.quadraticCurveTo(xb, ya, xb, ya + r);
        this.ctx.lineTo(xb, yb - r);
        this.ctx.quadraticCurveTo(xb, yb, xb - r, yb);
        this.ctx.lineTo(xa + r, yb);
        this.ctx.quadraticCurveTo(xa, yb, xa, yb - r);
        this.ctx.lineTo(xa, ya + r);
        this.ctx.quadraticCurveTo(xa, ya, xa + r, ya);
        this.ctx.fillStyle = this.backgroundColor;
        this.ctx.fill();
        this.ctx.closePath();
    }

    _drawVal()
    {
        const margin = (this.badgeSize * 0.18) / 2;
        this.ctx.beginPath();
        this.ctx.textBaseline = "middle";
        this.ctx.textAlign = "center";
        this.ctx.font = `bold ${this.badgeSize * 0.82}px Arial`;
        this.ctx.fillStyle = this.color;
        this.ctx.fillText(this.value, this.badgeSize / 2 + this.offset.x, this.badgeSize / 2 + this.offset.y + margin);
        this.ctx.closePath();
    }

    _drawFavicon()
    {
        this.faviconEL.setAttribute("href", this.dataURL);
    }

    _draw()
    {
        this._drawIcon();
        if (this.value) this._drawShape();
        if (this.value) this._drawVal();
        this._drawFavicon();
    }

    _setup()
    {
        this.faviconSize = this.img.naturalWidth;
        this.badgeSize = this.faviconSize * this.size;
        this.canvas.width = this.faviconSize;
        this.canvas.height = this.faviconSize;
        const sd = this.faviconSize - this.badgeSize;
        const sd2 = sd / 2;
        this.offset = {
            n: {
                x: sd2,
                y: 0
            },
            e: {
                x: sd,
                y: sd2
            },
            s: {
                x: sd2,
                y: sd
            },
            w: {
                x: 0,
                y: sd2
            },
            nw: {
                x: 0,
                y: 0
            },
            ne: {
                x: sd,
                y: 0
            },
            sw: {
                x: 0,
                y: sd
            },
            se: {
                x: 8,
                y: 8
            },
        }[this.position];
    }

    // Public functions / methods:

    update()
    {
        this._value = Math.min(99, parseInt(this._value, 10));
        if (this.img)
        {
            this._draw();
            if (this.onChange) this.onChange.call(this);
        } else
        {
            this.img = new Image();
            this.img.addEventListener("load", () =>
            {
                this._setup();
                this._draw();
                if (this.onChange) this.onChange.call(this);
            });
            this.img.src = this.src;
        }
    }

    get dataURL()
    {
        return this.canvas.toDataURL();
    }

    get value()
    {
        return this._value;
    }

    set value(val)
    {
        this._value = val;
        this.update();
    }
}
