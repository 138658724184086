window.title_reports = function ()
{

    return {

        chart_type: '',
        show_details_div: false,
        show_purchase_refi_chart: false,
        expand_open_deals: false,
        expand_open_marketing_deals: false,

        init()
        {
            if (document.URL.match(/closed/))
            {
                this.closed_chart();
            }
            if (document.URL.match(/purchase_refi/))
            {
                this.purchase_refi_chart();
            }
            if (document.URL.match(/tp_agents/))
            {
                this.get_tp_agents();
                this.tp_agents_chart();
                this.get_closings_by_agent();
                this.get_closings_by_agent(180);
                this.get_closings_by_agent(360);
            }
            if (document.URL.match(/in_process/))
            {
                this.get_deals_created_each_month();
            }
            if (document.URL.match(/marketing_results/))
            {
                this.get_deals_created_each_month('marketing');
                this.get_tp_agents('marketing');
                this.get_income_from_marketing();
            }
        },

        closed_chart()
        {
            let scope = this;
            axios.get('/reports/title/closed_chart')
                .then(function (response)
                {
                    let deals = response.data.deals_data;
                    let years = response.data.years;
                    let month_names = response.data.month_names;

                    const chart_line = Highcharts.chart('chart_closed_line', {
                        chart: {
                            type: 'line'
                        },
                        title: {
                            text: 'Total Deals',
                        },
                        xAxis: {
                            categories: month_names
                        },
                        yAxis: {
                            title: {
                                text: 'Deals'
                            }
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                            itemStyle: {
                                fontSize: '20px'
                            }
                        },
                        plotOptions: {
                            series: {
                                allowPointSelect: true
                            }
                        },
                        series: deals,
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        }
                    });

                    const chart_column = Highcharts.chart('chart_closed_column', {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: 'Total Deals',
                        },
                        xAxis: {
                            categories: month_names
                        },
                        yAxis: {
                            title: {
                                text: 'Deals'
                            }
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                            itemStyle: {
                                fontSize: '20px'
                            }
                        },
                        plotOptions: {
                            series: {
                                allowPointSelect: true
                            }
                        },
                        series: deals,
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        }
                    });

                    setTimeout(function() {
                        scope.chart_type = 'column';
                    }, 100);
                })

                .catch(function (error)
                {

                });

        },

        get_deals_created_each_month(type = null)
        {
            let scope = this;
            axios.get('/reports/title/get_deals_created_each_month', {
                params: {
                    type: type
                },
            })
                .then(function (response)
                {
                    if (type == 'marketing')
                    {
                        scope.$refs.deals_created_each_month_marketing.innerHTML = response.data;
                    } else
                    {
                        scope.$refs.deals_created_each_month.innerHTML = response.data;
                    }
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        purchase_refi_chart()
        {
            let scope = this;
            axios.get('/reports/title/purchase_refi_chart')
                .then(function (response)
                {
                    let years = response.data.years;
                    let deals_data = response.data.deals_data;

                    const purchase_refi_chart = Highcharts.chart('purchase_refi_chart', {
                        chart: {
                            type: 'column',
                        },
                        title: {
                            text: 'Transaction Type'
                        },
                        xAxis: {
                            categories: years,
                        },
                        yAxis: {
                            title: {
                                text: 'Deals'
                            }
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                            itemStyle: {
                                fontSize: '20px'
                            }
                        },
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        },
                        series: deals_data
                    });

                    setTimeout(function() {
                        scope.show_purchase_refi_chart = true;
                    }, 500);
                })

                .catch(function (error)
                {

                });
        },

        get_tp_agents(type = null)
        {
            let scope = this;
            axios.get('/reports/title/get_tp_agents', { params: { type: type } })
                .then(function (response)
                {
                    scope.$refs.tp_agents.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        tp_agents_chart()
        {
            axios.get('/reports/title/tp_agents_chart')
                .then(function (response)
                {
                    let years = response.data.years;
                    let deals = response.data.deals_data;

                    const chart = Highcharts.chart('tp_agents_chart', {
                        chart: {
                            type: 'column'
                        },
                        title: {
                            text: 'Transaction Type'
                        },
                        xAxis: {
                            categories: years,
                        },
                        yAxis: {
                            title: {
                                text: 'Deals'
                            }
                        },
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'top',
                            itemStyle: {
                                fontSize: '20px'
                            }
                        },
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 500
                                },
                                chartOptions: {
                                    legend: {
                                        layout: 'horizontal',
                                        align: 'center',
                                        verticalAlign: 'bottom'
                                    }
                                }
                            }]
                        },
                        series: deals
                    });
                })

                .catch(function (error)
                {

                });
        },

        get_closings_by_agent(days = null)
        {
            let scope = this;
            axios.get('/reports/title/get_closings_by_agent', { params: { days: days } })
                .then(function (response)
                {
                    if (days == 360)
                    {
                        scope.$refs.closings_by_agent_360_days.innerHTML = response.data;
                    } else if (days == 180)
                    {
                        scope.$refs.closings_by_agent_180_days.innerHTML = response.data;
                    } else
                    {
                        scope.$refs.closings_by_agent.innerHTML = response.data;
                    }
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        get_income_from_marketing()
        {
            let scope = this;
            axios.get('/reports/title/get_income_from_marketing')
                .then(function (response)
                {
                    scope.$refs.income_from_marketing.innerHTML = response.data;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        get_details(year, month, status, marketing = null, closed_only = null)
        {
            let scope = this;
            axios.get('/reports/title/get_details', {
                params: {
                    year: year,
                    month: month,
                    status: status,
                    marketing: marketing,
                    closed_only: closed_only
                },
            })
                .then(function (response)
                {
                    scope.$refs.details_table_data.innerHTML = response.data;
                    scope.show_details_div = true;
                })
                .catch(function (error)
                {
                    console.log(error);
                });
        },

        in_process_to_excel()
            {
                axios.get('/reports/title/in_process_to_excel')
                    .then(function (response)
                    {
                        window.open(response.data.file_location, '_blank');
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

    }

}