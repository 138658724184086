if (document.URL.match(/create_email_campaign/))
{

    window.create_email_campaign = function (
        batch_id,
        name,
        template_id,
        list_name,
        list_type,
        list_id,
        list_slug,
        list_count,
        category_id,
        from_company,
        subject,
        send_date,
        send_time,
        send_am_pm,
        manual_location_type,
        manual_states,
        manual_counties,
        manual_distance,
        manual_distance_from,
        manual_office_name,
        manual_producers)
    {

        return {
            batch_id: batch_id,
            name: name,
            template_id: template_id,
            list_name: list_name,
            list_type: list_type || 'Automated',
            list_id: list_id,
            list_slug: list_slug,
            list_count: list_count,
            category_id: category_id,
            from_company: from_company,
            show_name_options: false,
            subject: subject,
            send_date: send_date,
            send_time: send_time,
            send_am_pm: send_am_pm,
            location_type: manual_location_type || 'location',
            manual_states: JSON.parse(manual_states),
            manual_counties: JSON.parse(manual_counties),
            manual_distance: manual_distance,
            manual_distance_from: manual_distance_from,
            manual_office_name: manual_office_name,
            manual_producers: manual_producers,
            active_tab: 0,
            show_preview_email: false,
            show_view_list: false,
            manual_search_id: null,
            counties_label: 'Choose counties...',
            email_selected: false,
            recipients_selected: false,
            sending_details_selected: false,
            sort_order: 'asc',
            show_add_category: false,
            states: [],
            state_values: [
                { id: 1, name: 'MD', disabled: false },
                { id: 2, name: 'VA', disabled: false },
                { id: 3, name: 'DC', disabled: false },
                { id: 4, name: 'DE', disabled: false },
                { id: 5, name: 'PA', disabled: false },
            ],
            get states_label()
            {

                if (this.states.length === 0)
                {
                    this.$refs.listbox_button.disabled = true;
                    this.$refs.listbox_button.classList.add('cursor-not-allowed', 'opacity-50');
                    return 'Choose states...';
                }
                this.$refs.listbox_button.disabled = false;
                this.$refs.listbox_button.classList.remove('cursor-not-allowed', 'opacity-50');
                return this.states.length === 1 ? this.states[0].name : `${this.states.length} selected`;
            },
            counties: [],
            county_values: [],

            init()
            {
                let scope = this;

                scope.get_counties();
                scope.set_counties_label();

                scope.get_templates();

                setInterval(function ()
                {
                    scope.save_campaign();
                }, 5000);
            },

            set_values()
            {
                let scope = this;

                // template
                document.querySelectorAll('input[name="template_id"]').forEach(function (ele)
                {
                    if (ele.value == scope.template_id)
                    {
                        ele.checked = true;
                        scope.email_selected = true;
                    }
                });

                // recipients
                if (scope.list_type == 'Automated')
                {
                    document.getElementById('automated_list_button').click();
                    document.querySelectorAll('input[name="list_slug"]').forEach(function (ele)
                    {
                        if (ele.value == scope.list_slug)
                        {
                            ele.checked = true;
                            ele.closest('.list-div').querySelector('button').click();
                            scope.recipients_selected = true;
                        }
                    });
                } else
                {
                    document.getElementById('manual_list_button').click();

                    if (scope.location_type == 'location')
                    {
                        document.getElementById('location_tab').click();
                    } else
                    {
                        document.getElementById('distance_tab').click();
                    }

                    scope.manual_states.forEach(function (state)
                    {
                        document.querySelector('[data-state="' + state.name + '"]').click();
                    });

                    setTimeout(function ()
                    {
                        scope.set_counties();

                        scope.manual_counties.forEach(function (county)
                        {
                            document.querySelector('[data-county="' + county.name + '-' + county.state + '"]').click();
                        });

                        setTimeout(function ()
                        {
                            scope.set_counties_label();
                        }, 200);

                        setTimeout(function ()
                        {
                            scope.manual_search();
                        }, 500);

                    }, 200);

                    document.querySelector('input[name="distance"]').value = scope.manual_distance;
                    document.querySelector('input[name="distance_from"]').value = scope.manual_distance_from;
                    document.querySelector('input[name="office_name"]').value = scope.manual_office_name;
                    document.querySelectorAll('input[name="producers"]').forEach(function (ele)
                    {
                        if (ele.value == scope.manual_producers)
                        {
                            ele.checked = true;
                        }
                    });

                }

                // details
                if (scope.name)
                {
                    document.querySelector('input[name="email_name"]').value = scope.name;
                }
                if (scope.subject)
                {
                    document.querySelector('input[name="subject"]').value = scope.subject;
                }
                if (scope.send_date)
                {
                    document.querySelector('input[name="send_date"]').value = scope.send_date;
                    document.querySelector('select[name="send_time"]').value = scope.send_time;
                    document.querySelector('select[name="send_am_pm"]').value = scope.send_am_pm;
                }

                if (scope.from_company)
                {
                    document.querySelector('select[name="from_company"]').value = scope.from_company;
                }

                if (scope.category_id)
                {
                    document.querySelector('select[name="category_id"]').value = scope.category_id;
                }

                scope.update_sending_details_selected();

            },

            set_name_and_subject(name, subject)
            {
                document.querySelector('input[name="email_name"]').value = name;
                document.querySelector('input[name="subject"]').value = subject;
            },

            next_tab()
            {
                this.active_tab++;
            },

            prev_tab()
            {
                this.active_tab--;
            },

            get_templates()
            {
                let scope = this;
                axios.get('/email_marketing/get_templates')
                    .then(function (response)
                    {
                        document.getElementById('templates_div').innerHTML = response.data;
                        scope.set_values();
                    });
            },

            set_counties_label()
            {
                if (this.counties.length === 0)
                {
                    this.counties_label = 'Choose counties...';
                }
                this.counties_label = this.counties.length === 1 ? this.counties[0].name : `${this.counties.length} selected`;
            },

            set_recipients_selected()
            {
                document.querySelectorAll('input[name="list_slug"]').forEach(function (ele)
                {
                    ele.checked = false;
                });
                this.recipients_selected = false;
            },

            preview_email(template_id)
            {
                let scope = this;
                axios.get('/email_marketing/preview_email', {
                    params: {
                        template_id: template_id,
                    },
                })
                    .then(function (response)
                    {
                        scope.$refs.preview_email_div.innerHTML = '';
                        scope.show_preview_email = true;
                        let iframe = document.createElement('iframe');
                        iframe.srcdoc = response.data.html;
                        iframe.classList.add('w-full', 'h-[1000px]');
                        scope.$refs.preview_email_div.appendChild(iframe);
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            manual_search()
            {
                let scope = this;
                let distance = document.querySelector('input[name="distance"]').value || null;
                let distance_from = document.querySelector('input[name="distance_from"]').value || null;
                let office_name = document.querySelector('input[name="office_name"]').value || null;
                let producers = document.querySelector('input[name="producers"]:checked').value || null;

                document.getElementById('agents_count').innerHTML = '<span class="text-base font-normal">Getting Count <i class="fas fa-spinner fa-spin ml-2"></i></span>';

                document.querySelectorAll('.results-only').forEach(function (ele)
                {
                    ele.classList.remove('hidden');
                });

                let new_id = Math.random();
                scope.manual_search_id = new_id;
                setTimeout(function ()
                {
                    if (new_id != scope.manual_search_id)
                    {
                        return;
                    }

                    axios.get('/email_marketing/manual_search', {
                        params: {
                            batch_id: scope.batch_id,
                            location_type: scope.location_type,
                            states: scope.states,
                            counties: scope.counties,
                            distance: distance,
                            distance_from: distance_from,
                            office_name: office_name,
                            producers: producers,
                        },
                    })
                        .then(function (response)
                        {
                            // if old request
                            if (new_id != scope.manual_search_id)
                            {
                                return;
                            }
                            document.getElementById('agents_count').innerHTML = response.data.agents_count;
                            scope.recipients_selected = true;
                            scope.batch_id = response.data.batch_id;
                        })
                        .catch(function (error)
                        {
                        });
                }, 2000);

            },

            get_counties()
            {
                let scope = this;
                scope.counties = [];
                axios.get('/email_marketing/get_counties')
                    .then(function (response)
                    {
                        if (response.data.counties)
                        {
                            scope.county_values = response.data.counties;
                        }
                    });

            },

            set_counties()
            {
                let scope = this;
                scope.counties = [];
                document.querySelectorAll('.county-item').forEach(function (ele)
                {
                    ele.classList.add('hidden');
                });
                scope.states.forEach(function (state)
                {
                    document.querySelectorAll('.county.' + state.name).forEach(function (ele)
                    {
                        ele.closest('li').classList.remove('hidden');
                    });
                });
                scope.set_counties_label();
                // document.querySelectorAll('.county').forEach(function (ele)
                // {
                //     let state = '';
                //     scope.states.forEach(function (state)
                //     {
                //         if (ele.classList.contains(state.name))
                //         {
                //             ele.closest('li').classList.remove('hidden');
                //         } else
                //         {
                //             ele.closest('li').classList.add('hidden');
                //         }
                //     });
                // });
            },

            view_list(ele = null, sort_by = null)
            {
                let button_html = null;
                let scope = this;
                if (ele)
                {
                    button_html = ele.innerHTML;
                    show_loading_button(ele, 'Loading ...');
                }
                axios.get('/email_marketing/view_list', {
                    params: {
                        sort_by: sort_by,
                        sort_order: scope.sort_order,
                        batch_id: scope.batch_id,
                    },
                })
                    .then(function (response)
                    {
                        scope.sort_order = scope.sort_order == 'asc' ? 'desc' : 'asc';
                        scope.$refs.view_list_div.innerHTML = response.data;
                        scope.show_view_list = true;
                        if (ele)
                        {
                            ele.innerHTML = button_html;
                        }
                    })
                    .catch(function (error)
                    {
                        if (ele)
                        {
                            ele.innerHTML = button_html;
                        }
                    });
            },

            update_sending_details_selected()
            {
                let scope = this;

                let name = document.querySelector('input[name="email_name"]');
                let from_company = document.querySelector('select[name="from_company"]');
                let subject = document.querySelector('input[name="subject"]');
                let send_date = document.querySelector('input[name="send_date"]');
                let category_id = document.querySelector('select[name="category_id"]');

                if (from_company && from_company.value != '' && category_id.value == '')
                {
                    if (from_company.value == 'Taylor Properties')
                    {
                        category_id.value = '1'; // Recruiting
                    } else if (from_company.value == 'Heritage Title')
                    {
                        category_id.value = '2'; // Biz Dev
                    } else
                    {
                        category_id.value = '';
                    }
                }

                if (name && name.value != '' && from_company && from_company.value != '' && subject && subject.value != '' && send_date && send_date.value != '' && category_id && category_id.value != '')
                {
                    scope.sending_details_selected = true;
                } else
                {
                    scope.sending_details_selected = false;
                }

            },

            add_new_category(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Adding ... ');
                remove_form_errors();

                let new_category = scope.$refs.new_category.value;
                let formData = new FormData();
                formData.append('new_category', new_category);

                axios.post('/email_marketing/add_new_category', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'New category added successfully');
                        scope.$refs.new_category.value = '';
                        scope.show_add_category = false;
                        scope.$refs.category.innerHTML += `<option value="${new_category}" selected>${new_category}</option>`;
                        reset_select(scope.$refs.category);
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            save_campaign(ele = null, manual_save = false)
            {
                let scope = this;

                let checked_list = document.querySelector('input[name="list_slug"]:checked');
                let template_id = '';
                if (document.querySelector('input[name="template_id"]:checked'))
                {
                    template_id = document.querySelector('input[name="template_id"]:checked').value;
                }
                let list_slug = '';
                let list_name = '';
                let list_count = '';
                if (checked_list)
                {
                    list_slug = checked_list.value;
                    list_name = checked_list.getAttribute('data-list-name');
                    list_count = checked_list.getAttribute('data-list-count');
                }

                let name = document.querySelector('input[name="email_name"]').value;
                let from_company = document.querySelector('select[name="from_company"]').value;
                let subject = document.querySelector('input[name="subject"]').value;
                let send_date = document.querySelector('input[name="send_date"]').value;
                let send_time = document.querySelector('select[name="send_time"]').value;
                let send_am_pm = document.querySelector('select[name="send_am_pm"]').value;
                let category_id = document.querySelector('select[name="category_id"]').value;

                let manual_location_type = scope.location_type;
                let manual_states = JSON.stringify(scope.states);
                let manual_counties = JSON.stringify(scope.counties);
                let manual_distance = document.querySelector('input[name="distance"]').value || '';
                let manual_distance_from = document.querySelector('input[name="distance_from"]').value || '';
                let manual_office_name = document.querySelector('input[name="office_name"]').value || '';
                let manual_producers = document.querySelector('input[name="producers"]:checked').value || '';

                let button_html = null;

                if (ele)
                {
                    button_html = ele.innerHTML;
                    show_loading_button(ele, 'Saving ... ');
                }

                let formData = new FormData();
                formData.append('batch_id', scope.batch_id);
                formData.append('template_id', template_id);
                formData.append('list_type', scope.list_type);
                formData.append('list_slug', list_slug);
                formData.append('list_name', list_name);
                formData.append('list_count', list_count);
                formData.append('name', name);
                formData.append('from_company', from_company);
                formData.append('subject', subject);
                formData.append('send_date', send_date);
                formData.append('send_time', send_time);
                formData.append('send_am_pm', send_am_pm);
                formData.append('category_id', category_id);
                formData.append('manual_location_type', manual_location_type);
                formData.append('manual_states', manual_states);
                formData.append('manual_counties', manual_counties);
                formData.append('manual_distance', manual_distance);
                formData.append('manual_distance_from', manual_distance_from);
                formData.append('manual_office_name', manual_office_name);
                formData.append('manual_producers', manual_producers);

                axios.post('/email_marketing/save_campaign', formData)
                    .then(function (response)
                    {
                        if (manual_save)
                        {
                            notify('success', 'Campaign saved successfully');
                        }
                        if (ele)
                        {
                            ele.innerHTML = button_html;
                        }
                        let auto_save_time = document.getElementById('auto_save_time');
                        auto_save_time.innerHTML = 'Auto saved ' + new Date().toLocaleTimeString();
                        auto_save_time.classList.remove('bg-emerald-50');
                        auto_save_time.classList.add('bg-emerald-100');
                        setTimeout(function ()
                        {
                            auto_save_time.classList.remove('bg-emerald-100');
                            auto_save_time.classList.add('bg-emerald-50');
                        }, 1000);
                    })
                    .catch(function (error)
                    {
                        if (ele)
                        {
                            ele.innerHTML = button_html;
                        }
                    });
            },

            review_email_campaign()
            {
                let scope = this;

                scope.active_tab = 3;

                let checked_list = document.querySelector('input[name="list_slug"]:checked');
                let template_id = document.querySelector('input[name="template_id"]:checked').value;
                let list_slug = checked_list ? checked_list.value : null;
                let list_name = checked_list ? checked_list.getAttribute('data-list-name') : null;
                let list_count = checked_list ? checked_list.getAttribute('data-list-count') : null;
                let name = document.querySelector('input[name="email_name"]').value;
                let from_company = document.querySelector('select[name="from_company"]').value;
                let subject = document.querySelector('input[name="subject"]').value;
                let send_date = document.querySelector('input[name="send_date"]').value;
                let send_time = document.querySelector('select[name="send_time"]').value;
                let send_am_pm = document.querySelector('select[name="send_am_pm"]').value;
                let category_id = document.querySelector('select[name="category_id"]').value;
                let ele = scope.$refs.review_email_campaign_button;
                let button_html = ele.innerHTML;

                const now = new Date();
                let verify_send_date = new Date(send_date + ' ' + send_time + ' ' + send_am_pm);

                if (verify_send_date < now)
                {
                    notify('error', 'Send date cannot be in the past');
                    return;
                }

                show_loading_button(ele, 'Processing ... ');
                remove_form_errors();

                let formData = new FormData();
                formData.append('batch_id', scope.batch_id);
                formData.append('template_id', template_id);
                formData.append('list_type', scope.list_type);
                formData.append('list_slug', list_slug);
                formData.append('list_name', list_name);
                formData.append('list_count', list_count);
                formData.append('name', name);
                formData.append('from_company', from_company);
                formData.append('subject', subject);
                formData.append('send_date', send_date);
                formData.append('send_time', send_time);
                formData.append('send_am_pm', send_am_pm);
                formData.append('category_id', category_id);
                axios.post('/email_marketing/review_email_campaign', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        document.getElementById('review_email_campaign_div').innerHTML = response.data;

                        setTimeout(function ()
                        {
                            let iframe = document.createElement('iframe');
                            let html = document.getElementById('preview_email_template').innerHTML;
                            iframe.srcdoc = html;
                            iframe.classList.add('w-full', 'h-[1200px]');
                            document.getElementById('review_email_div').appendChild(iframe);
                        }, 200);
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            send_email_campaign(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let checked_list = document.querySelector('input[name="list_slug"]:checked');
                let bee_pro_template_id = document.querySelector('input[name="template_id"]:checked').value;
                let list_slug = checked_list ? checked_list.value : null;
                let list_name = checked_list ? checked_list.getAttribute('data-list-name') : null;
                let list_count = checked_list ? checked_list.getAttribute('data-list-count') : null;
                let name = document.querySelector('input[name="email_name"]').value;
                let from_company = document.querySelector('select[name="from_company"]').value;
                let subject = document.querySelector('input[name="subject"]').value;
                let send_date = document.querySelector('input[name="send_date"]').value;
                let send_time = document.querySelector('select[name="send_time"]').value;
                let send_am_pm = document.querySelector('select[name="send_am_pm"]').value;
                let category_id = document.querySelector('select[name="category_id"]').value;
                let formData = new FormData();
                formData.append('batch_id', scope.batch_id);
                formData.append('bee_pro_template_id', bee_pro_template_id);
                formData.append('list_type', scope.list_type);
                formData.append('list_slug', list_slug);
                formData.append('list_name', list_name);
                formData.append('list_count', list_count);
                formData.append('name', name);
                formData.append('from_company', from_company);
                formData.append('subject', subject);
                formData.append('send_date', send_date);
                formData.append('send_time', send_time);
                formData.append('send_am_pm', send_am_pm);
                formData.append('category_id', category_id);
                axios.post('/email_marketing/send_email_campaign', formData)
                    .then(function (response)
                    {
                        // set cookie
                        document.cookie = 'email_campaign_status=success; path=/';
                        window.location.href = '/email_marketing';

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            delete_template(ele, template_id)
            {
                if (confirm('Are you sure you want to delete this template?'))
                {
                    let scope = this;
                    let button_html = ele.innerHTML;
                    show_loading_button(ele, 'Deleting ... ');
                    remove_form_errors();

                    let formData = new FormData();
                    formData.append('template_id', template_id);

                    axios.post('/email_marketing/delete_template', formData)
                        .then(function (response)
                        {
                            ele.innerHTML = button_html;
                            scope.get_templates();

                        })
                        .catch(function (error)
                        {
                            display_errors(error, ele, button_html);
                            ele.innerHTML = button_html;
                        });
                }
            },

        }

    }

}