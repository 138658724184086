if (document.URL.match(/data_validation/))
{

    window.data_validation = function ()
    {

        return {

            init()
            {
                this.show_loading('statuses');
                this.show_loading('agents');
                this.show_loading('offices');
                this.show_loading('company_listings');
                this.get_statuses();
                this.get_agents();
                this.get_offices();
                this.get_company_listings();
            },
            show_loading(type)
            {
                let html = `
                <div class="z-90 flex h-full w-full flex-col items-center justify-around bg-white opacity-70 space-x-6">
                        <div class="text-xl mb-6">Gathering Data</div>
                        <div class="text-gray-700 opacity-75 flex-grow">
                            <i class="fa-solid fa-circle-notch fa-spin fa-4x"></i>
                        </div>
                </div>`;
                if (type == 'statuses')
                {
                    this.$refs.statuses_div.innerHTML = html;
                }
                if (type == 'agents')
                {
                    this.$refs.agents_div.innerHTML = html;
                }
                if (type == 'offices')
                {
                    this.$refs.offices_div.innerHTML = html;
                }
                if (type == 'company_listings')
                {
                    this.$refs.company_listings_div.innerHTML = html;
                }
            },
            get_statuses(refresh = 'no')
            {
                let scope = this;
                if (refresh == 'yes')
                {
                    scope.show_loading('statuses');
                }
                axios.get('/bright_api/data_validation/get_statuses', { params: { refresh: refresh } })
                    .then(function (response)
                    {
                        scope.$refs.statuses_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },
            update_status(status)
            {
                axios.get('/bright_api/data_validation/update_status', {
                    params: {
                        status: status
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Status is being updated');
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },
            get_agents(refresh = 'no')
            {
                let scope = this;
                if (refresh == 'yes')
                {
                    scope.show_loading('agents');
                }
                axios.get('/bright_api/data_validation/get_agents', { params: { refresh: refresh } })
                    .then(function (response)
                    {
                        scope.$refs.agents_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },
            update_agents(status)
            {
                axios.get('/bright_api/data_validation/update_agents')
                    .then(function (response)
                    {
                        notify('success', 'Agents are being updated');
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },
            get_offices(refresh = 'no')
            {
                let scope = this;
                if (refresh == 'yes')
                {
                    scope.show_loading('offices');
                }
                axios.get('/bright_api/data_validation/get_offices', { params: { refresh: refresh } })
                    .then(function (response)
                    {
                        scope.$refs.offices_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },
            update_offices(status)
            {
                axios.get('/bright_api/data_validation/update_offices')
                    .then(function (response)
                    {
                        notify('success', 'Offices are being updated');
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },
            get_company_listings(refresh = 'no')
            {
                let scope = this;
                if (refresh == 'yes')
                {
                    scope.show_loading('company_listings');
                }
                axios.get('/bright_api/data_validation/get_company_listings', { params: { refresh: refresh } })
                    .then(function (response)
                    {
                        scope.$refs.company_listings_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },

            update_company_listings()
            {
                axios.get('/bright_api/data_validation/update_company_listings')
                    .then(function (response)
                    {
                        notify('success', 'Company listings are being updated');
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },
            update_cache()
            {
                axios.get('/bright_api/data_validation/update_cache')
                    .then(function (response)
                    {
                        notify('success', 'Cache is being updated');
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            }
        }

    }

}