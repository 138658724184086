window.email_marketing = function ()
{
    return {
        show_success: false,
        calendar: null,
        events: [],
        show_calendar: false,
        show_list_stats: false,

        show_confirm_cancel_campaign: false,
        show_confirm_archive_campaign: false,
        show_confirm_delete_campaign: false,
        sort_by: 'send_date',
        sort_order: 'desc',
        page: 1,
        length: 25,
        search_value: '',
        from_company: '',
        list_name: '',
        email_status: '',
        category_id: '',
        start_date: '',
        end_date: '',

        show_send_test_email: false,

        init()
        {

            this.get_events();
            // if cookie exists
            if (document.cookie.includes('email_campaign_status=success'))
            {
                this.show_success = true;
                document.cookie = 'email_campaign_status=; path=/';
                setTimeout(() =>
                {
                    this.get_email_campaigns();
                }, 1000);
            }

            this.get_email_campaigns();
        },

        get_email_campaigns(page = 1)
        {
            let scope = this;

            axios.get('/email_marketing/get_email_campaigns', {
                params: {
                    page: page,
                    length: scope.length,
                    search: scope.search_value,
                    from_company: scope.from_company,
                    list_name: scope.list_name,
                    email_status: scope.email_status,
                    category_id: scope.category_id,
                    start_date: scope.start_date,
                    end_date: scope.end_date,
                    sort_by: scope.sort_by,
                    sort_order: scope.sort_order
                }
            })
                .then(response =>
                {
                    document.getElementById('campaigns_div').innerHTML = response.data;
                    scope.pagination_links();
                    scope.sort_by_clicked();
                    scope.sort_arrows();
                })
                .catch(error =>
                {
                    console.log(error);
                });
        },

        search_campaigns(search_value = '')
        {
            this.search_value = search_value;
            this.get_email_campaigns(1);
        },

        from_company_selected(value)
        {
            this.from_company = value;
            this.get_email_campaigns(1);
        },

        list_name_selected(value)
        {
            this.list_name = value;
            this.get_email_campaigns(1);
        },

        email_status_selected(email_status)
        {
            this.email_status = email_status;
            this.get_email_campaigns(1);
        },

        category_selected(category_id)
        {
            this.category_id = category_id;
            this.get_email_campaigns(1);
        },

        start_date_selected(start_date)
        {
            this.start_date = start_date;
            this.get_email_campaigns(1);
        },

        end_date_selected(end_date)
        {
            this.end_date = end_date;
            this.get_email_campaigns(1);
        },

        pagination_links()
        {
            document.querySelectorAll('.pagination-link').forEach(function (link)
            {
                let params = new URLSearchParams(link.href.split('?')[1]);
                let page = params.get('page');
                link.dataset.page = page;
                link.setAttribute('href', 'javascript:void(0);');
            });
        },

        pagination_link_clicked(el)
        {
            let page = el.dataset.page;
            this.page = page;
            this.get_email_campaigns(page);
        },

        pagination_select_changed(length)
        {
            this.length = length;
            this.get_email_campaigns(1);
        },

        sort_by_clicked()
        {
            let scope = this;
            document.querySelectorAll('.sort-by').forEach(function (el)
            {
                let sort_column = el.dataset.sort;

                el.addEventListener('click', function (e)
                {
                    e.preventDefault();
                    let order = 'asc';
                    if (sort_column === scope.sort_by)
                    {
                        order = scope.sort_order === 'asc' ? 'desc' : 'asc';
                    }
                    scope.sort_by = sort_column;
                    scope.sort_order = order;
                    el.dataset.sortorder = order;
                    scope.get_email_campaigns(1);
                });
            });
        },

        sort_arrows()
        {
            let scope = this;
            document.querySelectorAll('.sort-by').forEach(function (el)
            {
                let sort_column = el.dataset.sort;
                let icon = el.querySelector('i');

                icon.classList.remove('text-gray-800', 'fa-angle-down', 'fa-angle-up');
                icon.classList.add('fa-angles-up-down', 'text-gray-400');

                if (sort_column === scope.sort_by)
                {
                    icon.classList.remove('fa-angles-up-down', 'text-gray-400');
                    icon.classList.add('text-gray-800', 'font-bold');
                    if (scope.sort_order === 'desc')
                    {
                        icon.classList.add('fa-angle-down');
                    } else
                    {
                        icon.classList.add('fa-angle-up');
                    }
                }
            });
        },

        clear_filters()
        {
            let scope = this;

            scope.$refs.campaigns_search.value = '';
            scope.$refs.from_company.value = '';
            scope.$refs.list_name.value = '';
            scope.$refs.email_status.value = '';
            scope.$refs.category_id.value = '';
            scope.$refs.start_date.value = '';
            scope.$refs.end_date.value = '';

            this.search_value = '';
            this.from_company = '';
            this.list_name = '';
            this.email_status = '';
            this.category_id = '';
            this.start_date = '';
            this.end_date = '';

            setTimeout(() =>
            {
                this.get_email_campaigns(1);
            }, 100);

        },

        get_events()
        {
            let scope = this;
            axios.get('/email_marketing/get_events')
                .then(response =>
                {
                    scope.events = response.data;
                    scope.calendar_init();
                });
        },

        view_list_stats()
        {
            axios.get('/email_marketing/get_list_stats')
                .then(response =>
                {
                    this.$refs.list_stats_div.innerHTML = response.data;
                    this.show_list_stats = true;
                });
        },

        calendar_init()
        {
            this.calendar = new FullCalendar.Calendar(this.$refs.calendar_div, {
                events: this.events,
                initialDate: new Date().toISOString().split('T')[0],
                initialView: 'dayGridMonth',
                selectable: false,
                height: 'auto',

                eventClick: (info) =>
                {
                    info.jsEvent.preventDefault();
                    if (info.event.url)
                    {
                        window.open(info.event.url);
                    }
                },

            });

            this.setEventColors();

        },

        setEventColors()
        {
            let scope = this;
            let interval = setInterval(() =>
            {
                if (document.querySelectorAll('.fc-daygrid-event').length > 0)
                {
                    document.querySelectorAll('.fc-daygrid-event').forEach(function (el)
                    {
                        if (el.querySelector('.fc-event-title').innerText.match(/TP\s\|/))
                        {
                            el.classList.add('bg-sky-50');
                        } else if (el.querySelector('.fc-event-title').innerText.match(/HT\s\|/))
                        {
                            el.classList.add('bg-orange-50');
                        } else if (el.querySelector('.fc-event-title').innerText.match(/HF\s\|/))
                        {
                            el.classList.add('bg-violet-50');
                        }
                    });

                    document.querySelectorAll('.fc-prev-button, .fc-next-button').forEach(function (el)
                    {
                        el.addEventListener('click', function (e)
                        {
                            console.log('click');
                            scope.setEventColors();
                        });
                    });
                    clearInterval(interval);
                }
            }, 500);
        },

        getEventIndex(info)
        {
            return this.events.findIndex((event) => event.id == info.event.id)
        },

        view_calendar()
        {
            this.show_calendar = true;
            setTimeout(() =>
            {
                this.calendar.render();
            }, 500);
        },

        confirm_cancel_campaign(batch_id, action)
        {
            let scope = this;
            if (action == 'cancel')
            {
                scope.show_confirm_cancel_campaign = true;
                scope.$refs.cancel_campaign_batch_id.value = batch_id;
            } else if (action == 'archive')
            {
                scope.show_confirm_archive_campaign = true;
                scope.$refs.archive_campaign_batch_id.value = batch_id;
            } else if (action == 'delete')
            {
                scope.show_confirm_delete_campaign = true;
                scope.$refs.delete_campaign_batch_id.value = batch_id;
            }
        },

        cancel_campaign(ele, action)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            let batch_id;
            let success_message;

            if (action == 'cancel')
            {
                batch_id = scope.$refs.cancel_campaign_batch_id.value;
                show_loading_button(ele, 'Cancelling ... ');
                success_message = 'Campaign cancelled successfully';
            } else if (action == 'archive')
            {
                batch_id = scope.$refs.archive_campaign_batch_id.value;
                show_loading_button(ele, 'Archiving ... ');
                success_message = 'Campaign archived successfully';
            } else if (action == 'delete')
            {
                batch_id = scope.$refs.delete_campaign_batch_id.value;
                show_loading_button(ele, 'Deleting ... ');
                success_message = 'Campaign deleted successfully';
            }

            axios.post('/email_marketing/' + action + '_campaign', { batch_id: batch_id })
                .then(function (response)
                {
                    scope.show_confirm_cancel_campaign = false;
                    scope.show_confirm_archive_campaign = false;
                    scope.show_confirm_delete_campaign = false;
                    notify('success', success_message);
                    ele.innerHTML = button_html;
                    setTimeout(() =>
                    {
                        scope.get_email_campaigns(1);
                    }, 500);
                })
                .catch(function (error)
                {
                });
        },

        clone_campaign(ele, batch_id)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Cloning ... ');
            axios.post('/email_marketing/clone_campaign', { batch_id: batch_id })
                .then(function (response)
                {
                    window.location.href = '/email_marketing/create_email_campaign/' + response.data.batch_id;
                });
        },

        open_send_test_email(campaign_id)
        {
            let scope = this;
            scope.show_send_test_email = true;
            scope.$refs.campaign_id.value = campaign_id;
        },

        send_test_email(ele, email_addresses)
        {
            let scope = this;
            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Sending ... ');
            axios.post('/email_marketing/send_test_email', {
                campaign_id: scope.$refs.campaign_id.value,
                email_addresses: email_addresses
            })
                .then(function (response)
                {
                    notify('success', 'Test email sent successfully');
                    scope.show_send_test_email = false;
                });
        },
        update_stats_and_statuses(ele)
        {
            let scope = this;
            show_loading();
            ele.querySelector('i').classList.add('fa-spin');
            axios.get('/email_marketing/update_stats_and_statuses')
                .then(function (response)
                {
                    setTimeout(() =>
                    {
                        ele.querySelector('i').classList.remove('fa-spin');
                        notify('success', 'Email stats and statuses updated');
                        hide_loading();
                        scope.get_email_campaigns(1);
                    }, 1000);
                })
                .catch(function (error)
                {
                    ele.querySelector('i').classList.remove('fa-spin');
                });
        }

    }
}
